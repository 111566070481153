import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import JoinGlassHiveCallout from '../../components/JoinGlassHiveCallout';
import { Container, Row, Col } from 'react-bootstrap';
import { Waypoint } from 'react-waypoint';
import './style.scss';

const IndexPage = () => {
    useEffect(() => {});

    const animateImgs = (removeFrom, addTo) => {
        document
            .querySelectorAll('.' + removeFrom + '.animate')
            .forEach(img => img.classList.remove('animate'));

        document
            .querySelectorAll('.' + addTo)
            .forEach(img => img.classList.add('animate'));
    };

    return (
        <Layout navTheme="light">
            <Seo title="Managers" />
            <div className="manager-page">
                <Container className="purple-blob container-1">
                    <Row>
                        <Col className="centered-text">
                            <h1 className="white-text">
                                Let Data Reign Supreme
                            </h1>
                            <p className="hero-subtext white-text">
                                Make sales & marketing management a numbers game
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="grey-swish-background container-2 ">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#big-data-benchmarks-video')
                                .play();
                        }}
                    />
                    <Row className="big-data-benchmarks-row flexed-mobile">
                        <Col className="one-half max-width-480">
                            <h3 className="centered-mobile">
                                Big data and best-in-class benchmarks
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Sales and marketing management is like a game of
                                chess. It takes years of detailed analysis and
                                sound strategies to consistently win.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive provides you the immediate success
                                formula with analytics and benchmarks from
                                thousands of IT services providers around the
                                world from average to best in class.
                            </p>
                        </Col>
                        <Col className="one-half centered-text rel second-col width-55-perc">
                            <video
                                src={require('../../videos/big-data-benchmarks.mp4')}
                                muted
                                playsInline
                                autoPlay
                                loop
                                className="big-data-benchmarks-video hide-on-mobile"
                                id="big-data-benchmarks-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile transform-scale-1x25"
                                src={require('../../images/manager-page/Group 6974.png')}
                                alt="map and monthly analytics of emails,calls,meetings,and opportunities"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#see-forest-and-trees-video')
                                .play();
                        }}
                    />
                    <Row className="see-forest-and-trees-row flexed-mobile">
                        <Col className="one-half flexed-order-1">
                            <video
                                src={require('../../videos/see-forest-and-trees.mp4')}
                                muted
                                playsInline
                                autoPlay
                                loop
                                className="see-forest-and-trees-video hide-on-mobile"
                                id="see-forest-and-trees-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile"
                                src={require('../../images/manager-page/Group 7123.png')}
                                alt="sales team monthly quota versus actual"
                            />
                        </Col>
                        <Col className="one-half second-col max-width-500 ">
                            <h3 className="centered-mobile">
                                See the forest AND the trees
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive gives your sales and marketing teams
                                the same quality performance analytics that you
                                expect from your service delivery teams.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Data in hand, you can see the big picture of
                                your sales and marketing efforts without losing
                                track of the day-to-day.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-4">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector(
                                    '#built-in-easy-ab-testing-video'
                                )
                                .play();
                        }}
                    />
                    <Row className="built-in-easy-ab-testing-row flexed-mobile">
                        <Col className="one-half max-width-500 margin-left-10-perc">
                            <h3 className="centered-mobile">
                                A/B testing to get the best results
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Want to compare two awesome subject lines to see
                                which performs better on your list? Or maybe you
                                have two layouts you want to give a shot.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive’s automatic A/B testing will find your
                                best performing version and send it out to
                                maximize your results.
                            </p>
                        </Col>
                        <Col className="one-half centered-text second-col">
                            <video
                                src={require('../../videos/built-in-easy-ab-testing.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="built-in-easy-ab-testing-video hide-on-mobile"
                                id="built-in-easy-ab-testing-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile scale-up"
                                src={require('../../images/marketing/Group 6827.png')}
                                alt="a/b testing open and click rate comparison"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-6">
                    <Row>
                        <Col className="centered-text max-width-650">
                            <h3>Centralized dashboards</h3>
                            <p className="wrapLineSib">
                                The main dashboard in GlassHive brings in all
                                the most important data points you need at a
                                glance, from website visitors to the performance
                                of your most recent campaign.
                            </p>
                            <p className="wrapLineSib">
                                Quickly plan your daily goals and tasks with a
                                glance at your main dashboard, or easily dive
                                into deeper analytics.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="stats-img centered-text">
                            <span id="checkRun" className="false" />
                            <Waypoint
                                onEnter={() => {
                                    let isRun = document.querySelector(
                                        '#checkRun'
                                    ).classList.value;
                                    if (isRun === 'false') {
                                        document
                                            .querySelectorAll('.sales')
                                            .forEach(img =>
                                                img.classList.add('animate')
                                            );
                                        document.querySelector(
                                            '#checkRun'
                                        ).classList = 'true';
                                    }
                                }}
                            />
                            <img
                                className="tablet-img"
                                src={require('../../images/manager-page/mobile-grid.png')}
                                alt="sales dashboard analytics in tablet view"
                            />
                            <img
                                className="img-1 sales marketing-button"
                                src={require('../../images/manager-page/stats-imgs/Marketing.png')}
                                alt="marketing button"
                                onClick={() =>
                                    animateImgs('sales', 'marketing')
                                }
                                onKeyPress={() =>
                                    animateImgs('sales', 'marketing')
                                }
                                role="button"
                                tabIndex="0"
                            />
                            <img
                                className="img-2 sales"
                                src={require('../../images/manager-page/stats-imgs/Group 7209.png')}
                                alt="sales button"
                            />
                            <img
                                className="grey-block img-3 sales marketing"
                                src={require('../../images/manager-page/stats-imgs/Group 7125.png')}
                                alt="white card background"
                            />
                            <img
                                className="img-4 sales "
                                src={require('../../images/manager-page/stats-imgs/Group 466.png')}
                                alt="total leads on red gradient background"
                            />
                            <img
                                className="img-5 sales"
                                src={require('../../images/manager-page/stats-imgs/Group 468.png')}
                                alt="total meetings on orange gradient background"
                            />
                            <img
                                className="img-6 sales"
                                src={require('../../images/manager-page/stats-imgs/Group 467.png')}
                                alt="total opportunities on blue gradient background"
                            />
                            <img
                                className="img-7 sales"
                                src={require('../../images/manager-page/stats-imgs/Group 469.png')}
                                alt="total wins on green gradient background"
                            />
                            <img
                                className="img-8 sales"
                                src={require('../../images/manager-page/stats-imgs/Group 557.png')}
                                alt="total revenue on purple gradient background"
                            />
                            <img
                                className="img-9 sales"
                                src={require('../../images/manager-page/stats-imgs/Group 6649.png')}
                                alt="leads to meetings analytics"
                            />
                            <img
                                className="img-10 sales"
                                src={require('../../images/manager-page/stats-imgs/Group 4088.png')}
                                alt="monthly sales quota analytics"
                            />
                            <img
                                className="img-11 sales"
                                src={require('../../images/manager-page/stats-imgs/Group 4085.png')}
                                alt="calls summary on white background"
                            />
                            <img
                                className="img-12 sales"
                                src={require('../../images/manager-page/stats-imgs/Group 4083.png')}
                                alt="emails summary on white background"
                            />
                            <img
                                className="img-13 sales"
                                src={require('../../images/manager-page/stats-imgs/Group 4086.png')}
                                alt="notes summary on white background"
                            />
                            <img
                                className="img-14 sales"
                                src={require('../../images/manager-page/stats-imgs/Group 4087.png')}
                                alt="meetings summary on white background"
                            />
                            <img
                                className="img-15 sales"
                                src={require('../../images/manager-page/stats-imgs/Group 6667.png')}
                                alt="sales team rank table"
                            />
                            {/* START MARKETING IMAGES */}
                            <img
                                className="img-16 marketing"
                                src={require('../../images/manager-page/marketing-imgs/Group 7203.png')}
                                alt="marketing button"
                            />
                            <img
                                className="img-17 marketing"
                                src={require('../../images/manager-page/marketing-imgs/Group 7204.png')}
                                alt="sales button"
                                onClick={() =>
                                    animateImgs('marketing', 'sales')
                                }
                            />
                            <img
                                className="img-18 marketing"
                                src={require('../../images/manager-page/marketing-imgs/Group 466.png')}
                                alt="total leads on red gradient background"
                            />
                            <img
                                className="img-19 marketing"
                                src={require('../../images/manager-page/marketing-imgs/Group 468.png')}
                                alt="total meetings on orange gradient background"
                            />
                            <img
                                className="img-20 marketing"
                                src={require('../../images/manager-page/marketing-imgs/Group 467.png')}
                                alt="total form submissions on blue gradient background"
                            />
                            <img
                                className="img-21 marketing"
                                src={require('../../images/manager-page/marketing-imgs/Group 469.png')}
                                alt="total video plays on green gradient background"
                            />
                            <img
                                className="img-22 marketing"
                                src={require('../../images/manager-page/marketing-imgs/Group 6758.png')}
                                alt="meetings quota versus actual on white background"
                            />
                            <img
                                className="img-23 marketing"
                                src={require('../../images/manager-page/marketing-imgs/Group 6759.png')}
                                alt="opportunities quota versus actual on white background"
                            />
                            <img
                                className="img-24 marketing"
                                src={require('../../images/manager-page/marketing-imgs/Group 6760.png')}
                                alt="wins quota versus actual on white background"
                            />
                            <img
                                className="img-25 marketing"
                                src={require('../../images/manager-page/marketing-imgs/Group 6761.png')}
                                alt="average msa quota versus actual on white background"
                            />
                            <img
                                className="img-26 marketing"
                                src={require('../../images/manager-page/marketing-imgs/Group 7200.png')}
                                alt="monthly lead quota versus actual on white background"
                            />
                            <img
                                className="img-27 marketing"
                                src={require('../../images/manager-page/marketing-imgs/Group 7199.png')}
                                alt="possible new monthly revenue"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-7 pink-swish-section">
                    <Row>
                        <Col className="one-half max-width-500 margin-left-10-perc white-text">
                            <h3 className="white-text centered-mobile">
                                Deep campaign analytics
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Get a complete view of how your email campaigns
                                perform.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Besides open and click-through rate, GlassHive
                                allows you to see the per-prospect activity as
                                well as when activities took place.
                            </p>
                        </Col>
                        <Col className="one-half centered-text second-col">
                            <img
                                className="margin-bottom-50-neg"
                                src={require('../../images/manager-page/The Sceens - Perspective PSD Mockup - by Tranmautritam3.png')}
                                alt="campaign analytics"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-8">
                    <Waypoint
                        onEnter={() => {
                            document.querySelector('#leads-video').play();
                        }}
                    />
                    <Row className="leads-row flexed-mobile">
                        <Col className="one-half centered-text flexed-order-1">
                            <video
                                src={require('../../videos/anim-leads.mp4')}
                                muted
                                playsInline
                                className="leads-video hide-on-mobile"
                                id="leads-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile mobile-img"
                                src={require('../../images/manager-page/Group 6591.png')}
                                alt="leads table"
                            />
                        </Col>
                        <Col className="one-half max-width-500 second-col">
                            <h3 className="centered-mobile">
                                Point-based lead qualifications
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                We created a point system to assist you in
                                qualifying your leads for sales-readiness. Each
                                action, from email click through to form fill
                                has a unique score value that is easily viewable
                                within GlassHive.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-9">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector(
                                    '#get-everyone-on-same-page-video'
                                )
                                .play();
                        }}
                    />
                    <Row className="get-everyone-on-same-page-row flexed-mobile">
                        <Col className="one-half max-width-500 margin-left-10-perc">
                            <h3 className="centered-mobile">
                                Get everyone on the same page
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Struggling to prove that MQLs are maturing into
                                SQLs?
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive was developed by a bunch of sales and
                                marketing professionals, so we’re familiar with
                                the butting of heads between the two.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                That’s why, from day one, we made sure that both
                                sales and marketing would be operating from the
                                same data, the same list, and the same source of
                                truth.
                            </p>
                        </Col>
                        <Col className="one-half centered-text second-col">
                            <video
                                src={require('../../videos/get-everyone-on-same-page.mp4')}
                                muted
                                playsInline
                                autoPlay
                                loop
                                className="get-everyone-on-same-page-video hide-on-mobile"
                                id="get-everyone-on-same-page-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile"
                                src={require('../../images/manager-page/Group 7132.png')}
                                alt="contact profile"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-10">
                    <Waypoint
                        onEnter={() => {
                            document.querySelector('#reports-video').play();
                        }}
                    />
                    <Row className="reports-row flexed-mobile">
                        <Col className="one-half centered-text flexed-order-1">
                            <video
                                src={require('../../videos/reports.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="reports-video hide-on-mobile"
                                id="reports-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile"
                                src={require('../../images/manager-page/Group 6726.png')}
                                alt="report pages"
                            />
                        </Col>
                        <Col className="one-half max-width-500 second-col">
                            <h3 className="centered-mobile">
                                Easy-to-read reports
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                GlassHive automatically records activities and
                                organizes them by team member. With a few
                                clicks, you can see all emails and follow-ups as
                                well as the content contained within each.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                You can also configure GlassHive to
                                automatically export these reports so that you
                                can check in at regular intervals to keep your
                                team on track.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-11">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('#stay-on-top-wins-video')
                                .play();
                        }}
                    />
                    <Row className="stay-on-top-wins-row flexed-mobile">
                        <Col className="one-half max-width-500 margin-left-10-perc">
                            <h3 className="centered-mobile">
                                Stay on top of wins and losses
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                With built-in win-wires and lost opportunity
                                reporting, you can keep your team motivated and
                                aligned behind your goals.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Send out these reports to keep your team on the
                                same page and rallied around the cause.
                            </p>
                        </Col>
                        <Col className="one-half centered-text second-col">
                            <video
                                src={require('../../videos/stay-on-top-wins.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="stay-on-top-wins-video hide-on-mobile"
                                id="stay-on-top-wins-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile"
                                src={require('../../images/manager-page/Group 7134.png')}
                                alt="opportunities reports"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-12 grey-swish flexed-mobile flex-mobile">
                    <Row>
                        <Col className="centered-text max-width-650">
                            <h3>Stay ahead with marketing plans</h3>
                            <p className="wrapLineSib">
                                Planning your marketing is a fun and exciting
                                time full of optimism —executing, on the other
                                hand, can fall short of expectations.
                            </p>
                            <p className="wrapLineSib">
                                With marketing plans in GlassHive, you can
                                schedule all of your email campaigns ahead of
                                time so they will automatically send when you
                                need them to.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="calendar-img centered-text">
                            <Waypoint
                                onEnter={() => {
                                    document
                                        .querySelector('.calendar-img')
                                        .classList.add('animate');
                                }}
                            />
                            <img
                                className="tablet-img"
                                src={require('../../images/manager-page/mobile-calendar.png')}
                                alt="marketing plan calendar"
                            />
                            <img
                                className="calendar"
                                src={require('../../images/manager-page/Group 6712.png')}
                                alt="calendar"
                            />
                            <img
                                className="small-square"
                                src={require('../../images/manager-page/Group 7205.png')}
                                alt="marketing plan template preview on calendar"
                            />
                            <img
                                className="large-square"
                                src={require('../../images/manager-page/Group 6458.png')}
                                alt="marketing plan overview"
                            />
                            <img
                                className="fan-1"
                                src={require('../../images/manager-page/right-card.png')}
                                alt="campaign"
                            />
                            <img
                                className="fan-2"
                                src={require('../../images/manager-page/mid-card.png')}
                                alt="campaign"
                            />
                            <img
                                className="fan-3"
                                src={require('../../images/manager-page/left-card.png')}
                                alt="campaign"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-13">
                    <Waypoint
                        onEnter={() => {
                            document.querySelector('#pipeline-video').play();
                        }}
                    />
                    <Row className="pipeline-row flexed-mobile">
                        <Col className="one-half max-width-500 margin-left-10-perc">
                            <h3 className="centered-mobile">
                                Optimize your pipeline
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Get automated revenue projections on engagements
                                during each phase of your sales cycle.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                Use GlassHive’s automated marketing and sales
                                features to keep prospects moving through the
                                funnel more consistently.
                            </p>
                        </Col>
                        <Col className="one-half centered-text second-col">
                            <video
                                src={require('../../videos/pipeline.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="pipeline-video hide-on-mobile"
                                id="pipeline-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile"
                                src={require('../../images/manager-page/The Sceens - Perspective PSD Mockup - by Tranmautritam4.png')}
                                alt="analytics of engagement and revenue projection"
                            />
                        </Col>
                    </Row>
                </Container>
                <Container className="container-14">
                    <Waypoint
                        onEnter={() => {
                            var videoEl = document.querySelector(
                                '#build-and-automate-process-video'
                            );

                            videoEl.playbackRate = 0.8;
                            videoEl.play();
                        }}
                    />
                    <Row className="build-and-automate-process-row flexed-mobile">
                        <Col className="one-half centered-text flexed-order-1">
                            <video
                                src={require('../../videos/build-and-automate-process.mp4')}
                                muted
                                playsInline
                                autoPlay
                                className="build-and-automate-process-video hide-on-mobile"
                                id="build-and-automate-process-video"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <img
                                className="show-on-mobile"
                                src={require('../../images/manager-page/Group 6846.png')}
                                alt="journey steps"
                            />
                        </Col>
                        <Col className="one-half max-width-500 second-col">
                            <h3 className="centered-mobile">
                                Build and automate your process
                            </h3>
                            <p className="wrapLineSib centered-mobile">
                                Journeys within GlassHive allow you to send the
                                same series of campaigns to any prospect who
                                takes a certain action.
                            </p>
                            <p className="wrapLineSib centered-mobile">
                                When leveraged fully, you not only ensure there
                                is always correct follow up to an action, but
                                you also establish your brand the same way for
                                every one of your leads.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-15">
                    <Waypoint
                        onEnter={() => {
                            document
                                .querySelector('.join-glasshive-callout')
                                .classList.add('animate');
                        }}
                    />
                    <Row>
                        <Col className="three-fourth">
                            <JoinGlassHiveCallout />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default IndexPage;
